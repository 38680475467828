// src/components/Sidebar.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';  // Adjust the path to authContext

function Sidebar() {
  const { user, signOut } = useAuth(); // Access the user object from context
  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut();
    navigate('signin');
  };

  return (
    <div className="sidebar">
      <h2>Navigation</h2>
      <ul>
        {!user ? (
          // Show Sign In link when user is not authenticated
          <li><Link to="/signin">Sign In</Link></li>
        ) : (
          // Show other links when user is authenticated
          <>
            <li><Link to="/mypicks">My Picks</Link></li>
            <li><Link to="/submit-picks">Submit Picks</Link></li>
            <li><Link to="/editor">Editor</Link></li>
            <li>
              <button onClick={handleSignOut} className="signout-button">
                Sign Out
              </button>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}

export default Sidebar;
