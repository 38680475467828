// src/auth/SignIn.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';  // Import useAuth from authContext
import ChangePassword from './ChangePassword';

const SignIn = () => {
  const { signIn, loading, error } = useAuth();  // Get signIn, loading, and error from authContext
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [localError, setLocalError] = useState('');
  const [cognitoUser, setCognitoUser] = useState(null);
  const [challenge, setChallenge] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLocalError('');  // Clear any previous errors
    try {
      const { session, challenge, cognitoUser } = await signIn(username, password);  // Use signIn from authContext
      
      if (challenge === 'NEW_PASSWORD_REQUIRED') {
        setCognitoUser(cognitoUser);
        setChallenge(challenge);
      } else {
        navigate('/mypicks');  // Redirect after successful login
      }
    } catch (err) {
      setLocalError(err.message || 'Invalid credentials, please try again.');
    }
  };

  // If user needs to change their password, render the ChangePassword component
  if (challenge === 'NEW_PASSWORD_REQUIRED') {
    return <ChangePassword cognitoUser={cognitoUser} />;
  }

  return (
    <div>
      <h2>Sign In</h2>
      {/* Show global authContext error */}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Show local error */}
      {localError && <p style={{ color: 'red' }}>{localError}</p>}

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <button type="submit" disabled={loading}>
          {loading ? 'Signing In...' : 'Sign In'}
        </button>
      </form>
    </div>
  );
};

export default SignIn;
