// authContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { getSession, signOut as cognitoSignOut, signIn as cognitoSignIn } from '../auth/authService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSession = async () => {
      console.log('Fetching session...');  // Log when session fetch begins
      try {
        const session = await getSession();
        if (session) {
          console.log('Session found:', session);
          setUser({
            username: session.getIdToken().payload['cognito:username'],
            session,
          });
        } else {
          console.log('No session found');
          setUser(null);
        }
      } catch (err) {
        console.error('Failed to fetch session:', err);
        setUser(null);
        setError(err.message);
      } finally {
        console.log('Finished session fetch, setting loading to false');
        setLoading(false);  // Ensure loading is set to false after session fetch
      }
    };

    fetchSession();
  }, []);

  const signIn = async (username, password) => {
    try {
      setLoading(true);
      console.log('Signing in with:', username);
      
      const { session, challenge, cognitoUser } = await cognitoSignIn(username, password);
      
      if (!session || !session.getIdToken) {
        throw new Error('Session is invalid or missing.');
      }
  
      console.log('Sign in successful:', session);
  
      // Check if the session is valid before accessing properties
      setUser({
        username: session.getIdToken().payload['cognito:username'],  // Ensure session and payload exist
        session,
      });
  
      setError(null);  // Clear any existing error on success
      return { session, challenge, cognitoUser };  // Return session details
    } catch (err) {
      console.error('Sign in failed:', err);
      setError(err.message || 'Sign in failed.');
      setUser(null);  // Set user to null in case of error
      throw err;  // Ensure the error is still thrown to handle it in the SignIn component
    } finally {
      setLoading(false);
    }
  };
  
  const signOut = () => {
    console.log('Signing out...');
    cognitoSignOut();
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, loading, error, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
