import React, { useEffect, useState } from 'react';
import { listFilesInS3Folder, loadFileData } from '../services/utils'; // Import utility functions
import EditableTable from '../components/EditableTable';

const Editor = () => {
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [folder, setFolder] = useState('games/'); // Default folder is 'games/'

  // Fetch list of files when the selected folder changes
  useEffect(() => {
    const fetchFiles = async () => {
      setLoading(true);
      const fileList = await listFilesInS3Folder(folder); // Fetch files using the selected folder
      setFiles(fileList);
      setLoading(false);
    };
    fetchFiles();
  }, [folder]); // Re-fetch files when the folder changes

  // Handle file loading
  const handleFileLoad = async (fileKey) => {
    setLoading(true);
    const data = await loadFileData(fileKey);  // Load file data using the utility function
    setFileData(data);
    setSelectedFile(fileKey);
    setLoading(false);
  };

  // Handle folder selection change
  const handleFolderChange = (e) => {
    setSelectedFile(null); // Reset the selected file when folder changes
    setFileData(null); // Clear file data when switching folders
    setFolder(e.target.value); // Set the selected folder
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <h1>Select Folder</h1>
      {/* Dropdown to select between 'games/' and 'picks/' */}
      <select value={folder} onChange={handleFolderChange}>
        <option value="games/">Games</option>
        <option value="picks/">Picks</option>
      </select>

      <h2>List of JSON Files in {folder}</h2>

      {files.length > 0 ? (
        <ul>
          {files.map((file) => (
            <li key={file}>
              <button onClick={() => handleFileLoad(file)}>{file}</button>
            </li>
          ))}
        </ul>
      ) : (
        <div>No files available in {folder}</div>
      )}

      {fileData && (
        <div>
          <h2>Editing: {selectedFile}</h2>
          <EditableTable
            data={fileData}
            fileName={selectedFile.split('/').pop()} // Get the file name without the path
            folder={folder} // Pass the selected folder
          />
        </div>
      )}
    </div>
  );
};

export default Editor;
