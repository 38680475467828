import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { COGNITO_CONFIG } from './authConfig';

const userPool = new CognitoUserPool({
  UserPoolId: COGNITO_CONFIG.USER_POOL_ID,
  ClientId: COGNITO_CONFIG.APP_CLIENT_ID,
});

// Sign In function
export const signIn = (username, password) => {
  return new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: session => {
        resolve({ session, challenge: null });
      },
      onFailure: err => {
        reject(err);
      },
      newPasswordRequired: (userAttributes) => {
        resolve({ challenge: 'NEW_PASSWORD_REQUIRED', cognitoUser, userAttributes });
      }
    });
  });
};

// Complete new password challenge (for forced password change)
export const completeNewPasswordChallenge = (cognitoUser, newPassword) => {
  return new Promise((resolve, reject) => {
    cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
      onSuccess: session => {
        resolve(session);
      },
      onFailure: err => {
        reject(err);
      },
    });
  });
};

// Get current session
export const getSession = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);  // Reject if there is an error getting the session
        } else if (session && session.isValid()) {
          resolve(session);  // Resolve with the session if it's valid
        } else {
          resolve(null);  // Resolve with null if the session is invalid
        }
      });
    } else {
      resolve(null);  // No current user, resolve with null instead of rejecting
    }
  });
};

// Sign out user
export const signOut = () => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
  }
};
