import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getSession } from './authService';
import { authorizedAdmins } from './authConfig';

const ProtectedRoute = ({ children, requiresAdmin = false }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    getSession()
      .then((session) => {
        const username = session.getIdToken().payload['cognito:username'];

        // Set authentication to true
        setIsAuthenticated(true);

        // Check if the user is an admin and set isAdmin accordingly
        if (authorizedAdmins.includes(username)) {
          setIsAdmin(true);
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  // If route requires admin and user is not an admin, redirect to /mypicks or show access denied
  if (requiresAdmin && !isAdmin) {
    return <Navigate to="/mypicks" />;
  }

  // If the user is authenticated, render the children components
  return isAuthenticated ? children : <Navigate to="/signin" />;
};

export default ProtectedRoute;
