import React, { useState } from 'react';
import { useTable } from 'react-table';
import { uploadToS3 } from '../services/utils'; // Import the updated upload function

const EditableTable = ({ data, fileName, folder, session }) => {
  const [tableData, setTableData] = useState(data);

  const updateMyData = (rowIndex, columnId, value) => {
    const updatedData = tableData.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          [columnId]: value,
        };
      }
      return row;
    });
    setTableData(updatedData);
  };

  const saveData = async () => {
    const success = await uploadToS3(fileName, folder, tableData, session);
    if (success) {
      alert('File saved successfully!');
    } else {
      alert('Error saving file.');
    }
  };

  // Add a new row to the table
  const addNewRow = () => {
    // Assuming the JSON structure is an array of objects, we will create a new empty object
    const newRow = {};
    
    // Add keys with empty values based on the first row's keys
    Object.keys(tableData[0] || {}).forEach(key => {
      newRow[key] = ''; // Initialize with empty values
    });

    setTableData([...tableData, newRow]); // Add the new row to the table data
  };

  const EditableCell = ({ value: initialValue, row: { index }, column: { id }, updateMyData }) => {
    const [value, setValue] = useState(initialValue);

    const onChange = (e) => {
      setValue(e.target.value);
    };

    const onBlur = () => {
      updateMyData(index, id, value);
    };

    return <input value={value} onChange={onChange} onBlur={onBlur} />;
  };

  const defaultColumn = {
    Cell: EditableCell,
  };

  const columns = React.useMemo(() => {
    if (!tableData.length) return [];
    return Object.keys(tableData[0]).map((key) => ({
      Header: key,
      accessor: key,
    }));
  }, [tableData]);

  const tableInstance = useTable({ columns, data: tableData, defaultColumn, updateMyData });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div>
      <table {...getTableProps()} style={{ border: '1px solid black' }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const { key, ...rest } = column.getHeaderProps(); // Destructure key from props
                return (
                  <th key={key} {...rest}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const { key, ...rest } = cell.getCellProps(); // Destructure key from props
                  return (
                    <td key={key} {...rest}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <button onClick={addNewRow}>Add New Row</button>
      <button onClick={saveData}>Save</button>
    </div>
  );
};

export default EditableTable;
