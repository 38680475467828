import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ProtectedRoute from './auth/ProtectedRoute';
import SignIn from './auth/SignIn';
import Editor from './pages/Editor';
import MyPicks from './pages/MyPicks';
import SubmitPicks from './pages/SubmitPicks';
import { AuthProvider } from './context/authContext';
import Sidebar from './components/Sidebar'; // Import Sidebar from the new file
import './styles.css';  // Ensure you have proper styles

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="app-container">
          <Sidebar />  {/* Sidebar is now imported and used here */}
          <div className="main-content">
            <Routes>
              <Route path="/signin" element={<SignIn />} />
              {/* Protected Routes */}
              <Route path="/editor" element={<ProtectedRoute><Editor /></ProtectedRoute>} />
              <Route path="/mypicks" element={<ProtectedRoute><MyPicks /></ProtectedRoute>} />
              <Route path="/submit-picks" element={<ProtectedRoute><SubmitPicks /></ProtectedRoute>} />
            </Routes>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
