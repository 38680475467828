import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSession } from '../auth/authService';  
import { getCurrentWeek, uploadToS3 } from '../services/utils';  
import '../styles.css';

function SubmitPicks() {
    const [ncaaGames, setNcaaGames] = useState([]);
    const [nflGames, setNflGames] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentTime, setCurrentTime] = useState(new Date());
    const navigate = useNavigate();

    // Update the clock every second
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    // Fetch games data based on the current week
    const fetchGames = useCallback(async () => {
        try {
            const weekNumber = await getCurrentWeek();  // Get the current week number dynamically
            const url = `/games/week${weekNumber}.json`;

            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`Failed to fetch games data: ${response.statusText} (Status: ${response.status})`);
            }

            const data = await response.json();
            const ncaaData = data.filter(game => game.sport_title === 'NCAAF');
            const nflData = data.filter(game => game.sport_title === 'NFL');

            setNcaaGames(groupByDate(ncaaData));
            setNflGames(groupByDate(nflData));
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch games');
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchGames();
    }, [fetchGames]);

    // Group games by date
    const groupByDate = (games) => {
        return games.reduce((groupedGames, game) => {
            const date = new Date(game.commence_time).toLocaleDateString(undefined, {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
            if (!groupedGames[date]) {
                groupedGames[date] = [];
            }
            groupedGames[date].push(game);
            return groupedGames;
        }, {});
    };

    // Format commence_time to a local time string without the date
    const formatCommenceTime = (commence_time) => {
        return new Date(commence_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    // Handle team selection
    const handleSelection = (team, game) => {
        const sanitizedTeamName = team.startsWith('at ') ? team.slice(3) : team;
    
        // Determine if the selected team is the favorite or underdog
        const isFavorite = sanitizedTeamName === (game.favorite === 'Home' ? game.home_team : game.away_team);
        
        // If favorite, store spread as negative; if underdog, store spread as positive
        const adjustedSpread = isFavorite ? -Math.abs(game.spread ?? 0) : Math.abs(game.spread ?? 0);
    
        const updatedSelection = selectedTeams.find(selection => selection.gameId === game.id)
            ? selectedTeams.filter(selection => selection.gameId !== game.id)
            : [...selectedTeams, {
                selectedTeam: sanitizedTeamName,
                gameId: game.id,
                spread: adjustedSpread,  // Adjusted spread based on favorite/underdog
                homeTeam: game.home_team,
                awayTeam: game.away_team,
                commenceTime: game.commence_time,
                sportTitle: game.sport_title,
                result: null,
                home_team_score: null,
                away_team_score: null
            }];
    
        if (updatedSelection.length <= 10) {
            setSelectedTeams(updatedSelection);
        }
    };    

    // Check if a team is selected
    const isSelected = (team) => {
        const sanitizedTeamName = team.startsWith('at ') ? team.slice(3) : team;
        return selectedTeams.some(selection => selection.selectedTeam === sanitizedTeamName);
    };

    const isMaxSelectionReached = selectedTeams.length >= 10;

    // Handle form submission and upload to S3
    const handleSubmit = async (event) => {
        event.preventDefault();

        const session = await getSession();
        // const weekNumber = await getCurrentWeek();
        // const username = session.getIdToken().payload['cognito:username'];
        const fileName = `${session.getIdToken().payload['cognito:username']}-week${await getCurrentWeek()}.json`;

        // const success = await uploadPicksToS3(username, weekNumber, selectedTeams, session);
        const success = await uploadToS3(fileName, 'picks', selectedTeams, session);

        if (success) {
            // alert('Picks successfully uploaded!');
            navigate('/mypicks');
        } else {
            alert('Error uploading picks.');
        }
    };

    if (loading) return <div>Loading games...</div>;
    if (error) return <div>{error}</div>;

    return (
        <form onSubmit={handleSubmit}>
            <div className="submitpicks">
                <div className="status-and-clock">
                    <div className="selection-status">
                        You have selected {selectedTeams.length} of 10 teams
                    </div>
                    <div className="clock">
                        Current Time: {currentTime.toLocaleTimeString()}
                    </div>
                    <button type="submit" className="submit-button">
                        Submit Picks
                    </button>
                </div>

                <h2>NCAA Games</h2>
                {Object.keys(ncaaGames).map((date) => (
                    <div key={date}>
                        <h3 className="date-heading">{date}</h3>
                        <table className="game-table">
                            <thead>
                                <tr>
                                    <th className="center-align">Time</th>
                                    <th className="checkbox-align"></th>
                                    <th className="left-align">Favorite</th>
                                    <th className="center-align">Spread</th>
                                    <th className="right-align">Underdog</th>
                                    <th className="checkbox-align"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {ncaaGames[date].map((game) => {
                                    const favorite = game.favorite === 'Home' ? game.home_team : game.away_team;
                                    const underdog = game.favorite === 'Home' ? game.away_team : `at ${game.home_team}`;
                                    const gameStarted = new Date(game.commence_time) < new Date();

                                    return (
                                        <tr key={game.id}>
                                            <td className="center-align">{formatCommenceTime(game.commence_time)}</td>
                                            <td className="checkbox-align">
                                                <input
                                                    type="checkbox"
                                                    disabled={
                                                        (!isSelected(favorite) && gameStarted) || 
                                                        (isMaxSelectionReached && !isSelected(favorite))
                                                    }
                                                    checked={isSelected(favorite)}
                                                    onChange={() => handleSelection(favorite, game)}
                                                />
                                            </td>
                                            <td className="left-align">{favorite}</td>
                                            <td className="center-align">{game.spread ? game.spread.toFixed(1) : 'N/A'}</td>
                                            <td className="right-align">{underdog}</td>
                                            <td className="checkbox-align">
                                                <input
                                                    type="checkbox"
                                                    disabled={
                                                        (!isSelected(underdog) && gameStarted) || 
                                                        (isMaxSelectionReached && !isSelected(underdog))
                                                    }
                                                    checked={isSelected(underdog)}
                                                    onChange={() => handleSelection(underdog, game)}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                ))}

                <h2>NFL Games</h2>
                {Object.keys(nflGames).map((date) => (
                    <div key={date}>
                        <h3 className="date-heading">{date}</h3>
                        <table className="game-table">
                            <thead>
                                <tr>
                                    <th className="center-align">Time</th>
                                    <th className="checkbox-align"></th>
                                    <th className="left-align">Favorite</th>
                                    <th className="center-align">Spread</th>
                                    <th className="right-align">Underdog</th>
                                    <th className="checkbox-align"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {nflGames[date].map((game) => {
                                    const favorite = game.favorite === 'Home' ? game.home_team : game.away_team;
                                    const underdog = game.favorite === 'Home' ? game.away_team : `at ${game.home_team}`;
                                    const gameStarted = new Date(game.commence_time) < new Date();

                                    return (
                                        <tr key={game.id}>
                                            <td className="center-align">{formatCommenceTime(game.commence_time)}</td>
                                            <td className="checkbox-align">
                                                <input
                                                    type="checkbox"
                                                    disabled={
                                                        (!isSelected(favorite) && gameStarted) || 
                                                        (isMaxSelectionReached && !isSelected(favorite))
                                                    }
                                                    checked={isSelected(favorite)}
                                                    onChange={() => handleSelection(favorite, game)}
                                                />
                                            </td>
                                            <td className="left-align">{favorite}</td>
                                            <td className="center-align">{game.spread ? game.spread.toFixed(1) : 'N/A'}</td>
                                            <td className="right-align">{underdog}</td>
                                            <td className="checkbox-align">
                                                <input
                                                    type="checkbox"
                                                    disabled={
                                                        (!isSelected(underdog) && gameStarted) || 
                                                        (isMaxSelectionReached && !isSelected(underdog))
                                                    }
                                                    checked={isSelected(underdog)}
                                                    onChange={() => handleSelection(underdog, game)}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
        </form>
    );
}

export default SubmitPicks;
