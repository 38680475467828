// utils.js
import AWS from 'aws-sdk';
import { getSession } from '../auth/authService';

// Define S3 configuration
const S3_BUCKET = 'klumpfamily.com';
const REGION = 'us-west-2';  // Your S3 bucket region
const USER_POOL_ID = 'us-west-2_K2uEu75o4';  // Your Cognito User Pool ID
const IDENTITY_POOL_ID = 'us-west-2:be08e6e7-3969-4199-a7ff-cdcb7b564753';  // Your Cognito Identity Pool ID

// Initialize the AWS SDK
AWS.config.update({ region: REGION });

// Function to configure AWS credentials
const configureAWSCredentials = async (session) => {
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IDENTITY_POOL_ID,
    Logins: {
      [`cognito-idp.${REGION}.amazonaws.com/${USER_POOL_ID}`]: session.getIdToken().getJwtToken()
    }
  });

  // Ensure credentials are refreshed before using them
  await AWS.config.credentials.getPromise();
};

// Function to list .json files from a specific folder in S3
export const listFilesInS3Folder = async (folder, session) => {
  try {
    // Get the Cognito session and configure AWS credentials
    const session = await getSession();
    await configureAWSCredentials(session);

    const s3 = new AWS.S3();
    const params = {
      Bucket: S3_BUCKET,
      Prefix: folder,  // Specify the folder
    };

    const data = await s3.listObjectsV2(params).promise();
    const fileList = data.Contents.filter(file => file.Key.endsWith('.json')).map(file => file.Key);
    
    return fileList; // Return the list of .json file keys
  } catch (error) {
    console.error('Error fetching file list from S3:', error);
    return [];
  }
};

// Function to load a file's data from S3
export const loadFileData = async (fileKey) => {
  try {
    const session = await getSession();  // Get the Cognito session and configure AWS credentials
    await configureAWSCredentials(session);

    const s3 = new AWS.S3();
    const params = {
      Bucket: S3_BUCKET,
      Key: fileKey,
    };

    const response = await s3.getObject(params).promise();
    const jsonData = JSON.parse(response.Body.toString());
    return jsonData;  // Return the file data
  } catch (error) {
    console.error('Error fetching file data from S3:', error);
    return null;
  }
};

// Upload picks to S3 using AWS SDK and Cognito session
export async function uploadPicksToS3(username, weekNumber, picks, session) {
  const fileName = `${username}-week${weekNumber}.json`;

  // Set up AWS Cognito credentials with the session token
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: IDENTITY_POOL_ID,
      Logins: {
          [`cognito-idp.${REGION}.amazonaws.com/${USER_POOL_ID}`]: session.getIdToken().getJwtToken()
      }
  });

  // Ensure credentials are refreshed before using them
  await AWS.config.credentials.getPromise();

  // Initialize S3 service
  const s3 = new AWS.S3();

  // Define S3 upload parameters
  const params = {
      Bucket: S3_BUCKET,
      Key: `picks/${fileName}`,  // Save the file in the "picks" folder
      Body: JSON.stringify(picks),
      ContentType: 'application/json'
  };

  try {
      const response = await s3.putObject(params).promise();
      console.log('File uploaded successfully:', response);
      return true;
  } catch (error) {
      console.error('Error uploading file:', error);
      return false;
  }
}

// Upload file to S3
export async function uploadToS3(fileName, folder, body) {
  // Ensure that folder doesn't have a trailing slash
  const cleanedFolder = folder.endsWith('/') ? folder.slice(0, -1) : folder;

  // Ensure the file name doesn't start with a slash
  const cleanedFileName = fileName.startsWith('/') ? fileName.slice(1) : fileName;

  const filePath = `${cleanedFolder}/${cleanedFileName}`; // Properly concatenated path
  
  // Get the Cognito session and configure AWS credentials
  const session = await getSession();
  await configureAWSCredentials(session);

  // Initialize S3 service
  const s3 = new AWS.S3();

  // Define S3 upload parameters
  const params = {
      Bucket: S3_BUCKET,
      Key: filePath,  // Use the cleaned file path
      Body: JSON.stringify(body),
      ContentType: 'application/json'
  };

  try {
      const response = await s3.putObject(params).promise();
      console.log('File uploaded successfully:', response);
      return true;
  } catch (error) {
      console.error('Error uploading file:', error);
      return false;
  }
}

// Fetch games data from a JSON file or API
export const fetchGamesData = async () => {
  const url = process.env.REACT_APP_CURRENT_GAMES_URL || 'http://localhost:3000/football_games_next_week.json';

  try {
      const response = await fetch(url);
      if (!response.ok) {
          throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      return data;
  } catch (error) {
      console.error("Error fetching games data:", error);
      return null;
  }
};

// Function to fetch week ranges from JSON file
const fetchWeekRanges = async () => {
    try {
      const response = await fetch('/data/week-ranges.json');
      if (!response.ok) {
        throw new Error('Failed to fetch week ranges');
      }
      const weekRanges = await response.json();
      return weekRanges;
    } catch (error) {
      console.error('Error loading week ranges:', error);
      return null; // Return null or handle error appropriately
    }
  };
  
  // Utility function to get a specific week by its number
  export const getWeekByNumber = async (weekNumber) => {
    const weekRanges = await fetchWeekRanges();
    if (!weekRanges) return null;
  
    return weekRanges.find(week => week.weekNumber === weekNumber);
  };
  
  // Utility function to get the current week based on today's date
  export const getCurrentWeek = async () => {
    try {
        console.log('Fetching week ranges...');  // Log the start of the fetch process
        const weekRanges = await fetchWeekRanges();
        
        if (!weekRanges) {
            console.warn('No week ranges found. Returning null.');
            return null;  // Log a warning if weekRanges is null or undefined
        }

        console.log('Week ranges fetched successfully:', weekRanges);  // Log the fetched week ranges

        const today = new Date();
        console.log('Today\'s date:', today);  // Log the current date

        const currentWeek = weekRanges.find(week => {
            const start = new Date(week.start);
            const end = new Date(week.end);
            console.log(`Checking week ${week.weekNumber}: Start ${start}, End ${end}`);  // Log the start and end dates of each week

            // Check if today is within the current week range
            const isCurrentWeek = today >= start && today <= end;
            if (isCurrentWeek) {
                console.log(`Today falls in week ${week.weekNumber}.`);  // Log if this is the correct week
            }

            return isCurrentWeek;
        });

        if (currentWeek) {
            console.log(`Current week number found: ${currentWeek.weekNumber}`);  // Log the week number
            return currentWeek.weekNumber;  // Return only the weekNumber
        } else {
            console.warn('No current week found.');
            return null;  // Return null if no week matches today's date
        }
    } catch (error) {
        console.error('Error occurred while fetching the current week:', error);  // Log any errors that occur
        return null;
    }
  };

  
  // Utility function to get the start and end dates for a specific week
  export const getWeekRange = async (weekNumber) => {
    const week = await getWeekByNumber(weekNumber);
    if (!week) return null;
  
    return {
      start: week.start,
      end: week.end,
    };
  };
  
  // Example: Function to determine if a given date falls in a particular week
  export const isDateInWeek = async (date, weekNumber) => {
    const week = await getWeekByNumber(weekNumber);
    if (!week) return false;
  
    const targetDate = new Date(date);
    const startDate = new Date(week.start);
    const endDate = new Date(week.end);
  
    return targetDate >= startDate && targetDate <= endDate;
  };
  