// src/components/ChangePassword.js
import React, { useState } from 'react';
import { completeNewPasswordChallenge } from '../auth/authService';
import { useNavigate } from 'react-router-dom';

const ChangePassword = ({ cognitoUser }) => {
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await completeNewPasswordChallenge(cognitoUser, newPassword);
      navigate('/landing'); // Redirect to landing page after successful password change
    } catch (err) {
      setError(err.message || 'Failed to change password, please try again.');
    }
  };

  return (
    <div>
      <h2>Change Password</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ChangePassword;
