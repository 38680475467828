import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getCurrentWeek } from '../services/utils';
import { authorizedAdmins } from '../auth/authConfig';
import AuthContext from '../context/authContext';  // Import AuthContext
import "../styles.css"; // Import your centralized CSS file

const MyPicks = () => {
  const { user, loading: authLoading, signOut } = useContext(AuthContext);  // Get auth state from context
  const [picks, setPicks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPicks = async () => {
      try {
        // If the user isn't logged in, navigate to the signin page
        if (!user && !authLoading) {
          navigate('/signin');
          return;
        }

        // Check if user is admin
        if (user && authorizedAdmins.includes(user.username)) {
          setIsAdmin(true);
        }

        const weekNumber = await getCurrentWeek();
        const picksUrl = `/picks/${user.username}-week${weekNumber}.json`;

        const response = await fetch(picksUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch picks');
        }

        const data = await response.json();
        setPicks(data);
        setLoading(false); 
      } catch (err) {
        setError(err.message);
        setLoading(false); 
      }
    };

    if (user) {
      fetchPicks();  // Fetch picks only after the user is available
    }
  }, [user, authLoading, navigate]);

  // Helper function to format the commence time (e.g., "Tue 6:00pm")
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleString("en-US", {
      weekday: "short", 
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, 
    });
  };

  // Function to determine the CSS class based on result
  const getResultClass = (result) => {
    switch (result) {
      case 'W':
        return 'selected-team-win';   // Green for Win
      case 'L':
        return 'selected-team-loss';  // Red for Loss
      case 'T':
        return 'selected-team-tie';   // Gray for Tie
      default:
        return '';  // No highlight for picks without a result
    }
  };

  // Function to count Wins, Losses, and Ties
  const calculateRecord = () => {
    let wins = 0, losses = 0, ties = 0;

    picks.forEach((pick) => {
      switch (pick.result) {
        case 'W':
          wins++;
          break;
        case 'L':
          losses++;
          break;
        case 'T':
          ties++;
          break;
        default:
          break;  // Ignore if result is not W, L, or T
      }
    });

    return { wins, losses, ties };
  };

  // Get the user's current record
  const { wins, losses, ties } = calculateRecord();

  // Handle sign out (from AuthContext)
  const handleSignOut = () => {
    signOut();
    navigate("/signin");
  };

  // Show loading state until auth context and picks are both resolved
  if (authLoading || loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="submitpicks">
      <h2>My Game Picks</h2>
      <h3>Current Record: {wins}-{losses}-{ties}</h3> {/* Display the record here */}

      <table className="game-table">
        <thead>
          <tr>
            <th className="center-align">Time</th>
            <th className="left-align">Favorite</th>
            <th className="center-align">Favorite Score</th>
            <th className="center-align">Spread</th>
            <th className="center-align">Underdog Score</th>
            <th className="right-align">Underdog</th>
          </tr>
        </thead>
        <tbody>
          {picks.length > 0 ? (
            picks.map((pick) => {
              const {
                selectedTeam, homeTeam, awayTeam, spread, commenceTime,
                home_team_score, away_team_score, result, gameId
              } = pick;

              let favoriteTeam, underdogTeam;
              if (spread < 0) {
                favoriteTeam = selectedTeam;
                underdogTeam = selectedTeam === homeTeam ? awayTeam : homeTeam;
              } else {
                underdogTeam = selectedTeam;
                favoriteTeam = selectedTeam === homeTeam ? awayTeam : homeTeam;
              }

              const favoriteScore = homeTeam === favoriteTeam ? home_team_score : away_team_score;
              const underdogScore = homeTeam === underdogTeam ? home_team_score : away_team_score;

              const selectedClass = getResultClass(result);

              return (
                <tr key={gameId}>
                  <td className="center-align">{formatDate(commenceTime)}</td>
                  <td className={`left-align ${selectedTeam === favoriteTeam ? 'selected-team-name' : ''}`}>
                    {favoriteTeam}
                  </td>
                  <td className={`center-align ${selectedTeam === favoriteTeam ? selectedClass : ''}`}>
                    {favoriteScore}
                  </td>
                  <td className="center-align">{spread.toFixed(1)}</td>
                  <td className={`center-align ${selectedTeam === underdogTeam ? selectedClass : ''}`}>
                    {underdogScore}
                  </td>
                  <td className={`right-align ${selectedTeam === underdogTeam ? 'selected-team-name' : ''}`}>
                    {underdogTeam}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="6" className="center-align">
                No picks available for this week.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="button-group">
        <Link to="/submitpicks">
          <button>Submit New Picks</button>
        </Link>

        <button onClick={handleSignOut}>
          Sign Out
        </button>

        {isAdmin && (
          <Link to="/editor">
            <button>Edit Data</button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default MyPicks;
